<template>
  <v-app v-if="$route.path.includes('/verified')">
    <!-- <v-navigation-drawer
      v-model="drawer"
      app
      dense
      clipped
    >
      <v-sheet
        color="grey lighten-4"
        class="pa-2"
      >
        <v-avatar
          class="mb-4"
          color="grey darken-1"
          size="64"
        ></v-avatar>

        <div>john@vuetifyjs.com</div>
      </v-sheet>

      <v-divider></v-divider>
    </v-navigation-drawer> -->
    <v-app-bar
      app
      color="white"
      dense
      elevation="1"
      class="pr-0"
      clipped-left
    >
      <!-- <v-img src="./assets/logo.svg" contain max-height="36" width="240px" max-width="40vw" class="ml-2" position="left" /> -->
      <span class="pl-2 text-h5">CPRINT</span>
      <v-spacer />
      <v-menu offset-y right max-width="160">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            class="px-2"
            large
            v-bind="attrs"
            v-on="on"
          >
            <v-avatar
              size="32"
              class="mr-2"
              color="teal"
            ><span class="white--text">{{ $store.state.user.dname.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase() }}</span></v-avatar>
            <span>{{ $store.state.user.dname }}</span>
            <v-icon right class="ml-0">mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            @click="item.clicked"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
  <v-app v-else>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data () {
    return {
      //
      drawer: null,
      items: [
        {
          title: 'Change Password',
          clicked: () => {
            alert('Please contact the developers to change password.')
            // this.$router.push('/change-password')
          }
        },
        {
          title: 'Logout',
          clicked: this.logoutFunc
        }
      ]
    }
  },
  methods: {
    logoutFunc () {
      localStorage.removeItem('TBFhgR213')
      this.$router.push('/login')
    }
  },
  mounted () {
    if (this.$route.name !== 'login') {
      // eslint-disable-next-line dot-notation
      if (!this.axios.defaults.headers.common['Authorization']) {
        this.$router.push('/login')
      }
    }
  }
}
</script>

<style lang="scss">
.v-toolbar__content, .v-toolbar__extension {
    padding: 4px 0 !important;
}
</style>
